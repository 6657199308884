import React from 'react';
import OverlayBox from './OverlayBox';
import Button from './Button';

type Props<T> = {
  close: () => void;
  commit: (item: T) => Promise<void>;
  item: T;
  itemIdentifier: string;
  itemName?: string;
  copyOverride?: React.ReactNode;
};

const DeleteConfirmation = <T,>({
  close,
  commit,
  item,
  itemIdentifier,
  itemName,
  copyOverride,
}: Props<T>) => {
  return (
    <div>
      <OverlayBox active header={`Radera ${itemIdentifier}?`} close={close}>
        {copyOverride || (
          <>
            <p>
              Vill du <strong>permanent radera</strong> {itemIdentifier}?
            </p>
            <p>
              Det går <strong>inte</strong> att återskapa en raderad {itemName}.
            </p>
          </>
        )}
        <Button
          data-test="commit-delete-button"
          look="danger"
          small
          onClick={() => commit(item)}
        >
          Radera
        </Button>
        <Button small onClick={close}>
          Avbryt
        </Button>
      </OverlayBox>
    </div>
  );
};

export default DeleteConfirmation;
