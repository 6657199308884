import React, { ChangeEventHandler, FormEventHandler, useState } from 'react';
import Button from './Button';
import { MunipRegBM } from '@bm-js/h2o-shared';

type Props = {
  handleSubmit: (formData: Partial<MunipRegBM>) => void;
};

const MunipRegForm = ({ handleSubmit }: Props) => {
  const [formData, setFormData] = useState<Partial<MunipRegBM>>({
    customerName: '',
    munipName: '',
  });

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    handleSubmit(formData);
  };

  return (
    <form onSubmit={onSubmit}>
      <input
        type="text"
        value={formData.munipName}
        placeholder="Kommunnamn"
        name="munipName"
        onChange={onChange}
      />
      <input
        type="text"
        value={formData.customerName}
        placeholder="Kundnamn"
        name="customerName"
        onChange={onChange}
      />
      <Button type="submit">Spara</Button>
    </form>
  );
};

export default MunipRegForm;
