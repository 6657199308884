export type Pick<T, K extends keyof T> = {
  [P in K]: T[P];
};

export const pick = <T extends Record<string, any>>(
  obj: T,
  keys: (keyof T)[]
) => {
  return Object.keys(obj).reduce((acc, key: keyof T) => {
    if (keys.includes(key)) acc[key] = obj[key];
    return acc;
  }, {} as T);
};
