import React from 'react';
import styled from 'styled-components';
import { colors } from '../utils/siteVars';
import BtnNoStyle from './BtnNoStyle';
import Card from './Card';

const StyledAdminCard = styled.div`
  display: grid;
  grid-template-columns: auto 3rem;
  align-items: center;
  .handle-customer-btn-container {
    margin-top: 1rem;
    margin-left: 1rem;
    position: relative;
    .handle-customer-menu-container {
      position: absolute;
      top: 1rem;
      right: 1rem;
      padding: 1rem;
      border-radius: 10px;
      background: ${colors.white};
      box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.1);
      display: grid;
      grid-template-columns: 1fr;
      gap: 0.5rem;
      opacity: 0;
      pointer-events: none;
      transition: 100ms ease-in-out;
      z-index: 2;
      a {
        display: block;
        width: 100%;
      }
      button {
        width: 100%;
        white-space: nowrap;
      }
    }
    &:hover {
      .handle-customer-menu-container {
        opacity: 1;
        pointer-events: initial;
      }
    }
  }
`;

type Props = {
  cardContent: React.ReactNode;
  menuContent: React.ReactNode;
};

const AdminCard = ({ cardContent, menuContent }: Props) => {
  return (
    <StyledAdminCard>
      <Card inner>{cardContent}</Card>
      <div className="handle-customer-btn-container">
        <BtnNoStyle>
          <img src="/icons/3-dots.png" alt="" width="100%" />
        </BtnNoStyle>
        <div className="handle-customer-menu-container">{menuContent}</div>
      </div>
    </StyledAdminCard>
  );
};

export default AdminCard;
